import React from 'react'

export default function Banners() {

  return (
		<div className='d-none d-sm-block mb-4'>
			<div className="col-12 col-sm-9 bg-dark mx-auto mt-5">
				<div className="container col-12 border border-dark">
					
					<div className="row justify-content-between my-3">
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://erosvipclub.com/" target="_blank" loading="eager" rel="noreferrer">
								<img width={480} height={60} src="/images/jobs/bottom/1000.png" placeholder="DOMINANT_COLOR" loading="lazy" alt="Escorts, Prepagos banner jobs" quality="100" />
							</a>
						</div>
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://www.escort-galleries.com/" target="_blank" loading="eager" rel="noreferrer">
								<img width={480} height={60} src="https://www.escort-galleries.com/images/banner/banner_46860.gif" loading="lazy" alt="Escort Galleries" />
							</a>
						</div>
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://skyescorts.com" title="Skyescorts.com Free Escort Directory" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://skyescorts.com/468x60sky_red.jpg" loading="lazy" alt="Skyescorts.com Free Escort Directory" />
							</a>
						</div>
					</div>
					
					<div className="row justify-content-between my-3">
						
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://escortmodels.org/" target="_blank" rel="noreferrer noopener" title="Worldwide Escort Directory">
								<img width={480} height={60} loading="lazy" src="https://i.ibb.co/n6s3HR4/Escortmodels-Banner-01.jpg"  alt="escortmodels.org/" title="Escortmodels.org" />
							</a>
						</div>
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://escortmodels.org/" target="_blank" rel="noreferrer noopener" title="Worldwide Escort Directory">
								<img width={480} height={60} loading="lazy" src="https://i.ibb.co/n6s3HR4/Escortmodels-Banner-01.jpg" alt="escortmodels.org/" title="Escortmodels.org" />
							</a>
						</div>

						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://allescortsguide.com/" target="_blank" rel="noreferrer noopener" title="All Escorts Guide">
								<img width={480} height={60}loading="lazy" src="https://allescortsguide.com/uploads/banner/468x60new.gif" alt="All Escorts Guide Directory" title="Worldwide Escort Directory" />
							</a>
						</div>										
					</div>
					
					<div className="row justify-content-between my-3">
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://amsterdamluxescort.com/" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} class="alignnone wp-image-313 size-full" src="https://amsterdamluxescort.com/wp-content/uploads/2020/01/468x60-Lux-Escort-Amsterdam.jpg" alt="Escort Amsterdam" />
							</a>
						</div>
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://www.londonhotescort.com/" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} class="alignnone wp-image-1270 size-full" src="https://www.londonhotescort.com/wp-content/uploads/2020/04/468-x-60-.jpg" alt="London Hot Escort" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://www.girlscompanionamsterdam.com/" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} class="alignnone wp-image-1436 size-full" src="https://www.girlscompanionamsterdam.com/wp-content/uploads/2020/01/468x60.jpg" alt="Escort Amsterdam" />
							</a>
						</div>	
					</div>

					<div className="row justify-content-between my-3">						
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://escortdirectory.xxx/"  target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} class="alignnone wp-image-12845 size-full" src="https://escortdirectory.xxx/wp-content/uploads/2020/05/468-x-60.jpg" alt="La celestina" />
							</a>
						</div>
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://ladyescortamsterdam.com/" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} class="alignnone wp-image-450 size-full" src="https://ladyescortamsterdam.com/wp-content/uploads/2021/12/468x60Lady.jpg" alt="Lady Escort Amsterdam" />
							</a>
						</div>
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://hotescortdusseldorf.com/" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} class="alignnone wp-image-235 size-full" src="https://hotescortdusseldorf.com/wp-content/uploads/2020/04/468-x-60.jpg" alt="Dusseldorf Hot Escort" />
							</a>
						</div>
						
					</div>
					
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://www.escortamsterdam1.com/" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} alt="Escort Amsterdam" src="https://static.wixstatic.com/media/925b42_34543b54be0749d4819d7d856a43338a~mv2.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://www.cupidescorts.co.uk/escorts/dublin/" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://www.cupidescorts.co.uk/wp-content/uploads/2021/04/cupid-1.jpg" alt="Dublin Escorts" border="0" />
							</a> 
						</div>
						
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://chinesegirlkl.com/" title="Chinese Girl KL Escort Sex" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} decoding="async" src="https://chinesegirlkl.com/wp-content/uploads/2023/02/Chinese-Girl-KL-1.gif" />
							</a>
						</div>			
					</div>
					
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-5">
							<a href="http://www.beneluxxx.com" target="_blank" rel="noreferrer noopener">
								<img src="http://www.beneluxxx.com/images/banners/n/v1/468x60.gif" border="0"  alt="beneluxxx escorts" />
							</a>
						</div>	
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-5">
							<a href="https://amasens.com" title="Amasens" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://amasens.com/amasens-logo.png" alt="Amasens" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-2">
							<a href="https://babesescortamsterdam.com/" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} class="alignnone wp-image-561 size-full" src="https://babesescortamsterdam.com/wp-content/uploads/2020/01/250x60.jpg" alt="Escort Amsterdam" />
							</a>
						</div>	
					</div>









					<div className="row justify-content-between my-3">
						<div className="mx-auto my-xs-3 col-9 col-md-4 ">
							<a href="https://www.classificadosx.com/" target="_blank" loading="eager" rel="noreferrer">
								<img width={480} height={60} src="/images/jobs/bottom/1001.png" placeholder="placeholder1" loading="lazy" alt="banner 1, Dama de Compañia" quality="100" />
							</a>
						</div>
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://www.pecattus.com.br/" target="_blank" loading="eager" rel="noreferrer">
								<img width={480} height={60} src="/images/jobs/bottom/2.png" placeholder="placeholder2"  loading="lazy" alt="banner, Dama de Compañia" quality="100" />
							</a>
						</div>
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://adultgaga.com/phpld/" target="_blank" loading="eager" rel="noreferrer">
								<img width={480} height={60} src="/images/jobs/bottom/4.png" placeholder="DOMINANT_COLOR"  loading="lazy" alt="Banner Jobs La Celesstina 1" quality="100" />
							</a>
						</div>
					</div>
					<div className="row justify-content-between my-3">
						
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://www.world-escort-guide.com/" target="_blank" loading="eager" rel="noreferrer">
								<img width={480} height={60} src="/images/jobs/bottom/5.png" placeholder="DOMINANT_COLOR" loading="lazy" alt="Banner Jobs La Celesstina 2" quality="100" />
							</a>
						</div>
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://www.escortdude.com/" target="_blank" loading="eager" rel="noreferrer">
								<img width={480} height={60} src="/images/jobs/bottom/92.png" placeholder="DOMINANT_COLOR" loading="lazy" alt="Banner Jobs La Celesstina 8" quality="100" />
							</a>
						</div>
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://escortreal.com/" target="_blank" loading="eager" rel="noreferrer">
								<img width={480} height={60} src="/images/jobs/bottom/8.png" placeholder="DOMINANT_COLOR" loading="lazy" alt="Banner Jobs La Celesstina 5" quality="100" />
							</a>
						</div>						
					</div>
					
					<div className="row justify-content-between my-3">
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://moscow-domina.escortbook.com/#bd3312" target="_blank" loading="eager" rel="noreferrer">
								<img width={480} height={60} src="/images/jobs/bottom/991.png" placeholder="DOMINANT_COLOR" loading="lazy" alt="jobs banners Dama de Compañia 13" quality="100" />
							</a>
						</div>
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://www.ilhadoprazer.com.br/" target="_blank" loading="eager" rel="noreferrer">
								<img width={480} height={60} src="/images/jobs/bottom/97.png" placeholder="DOMINANT_COLOR" loading="lazy" alt="Escorts, banners jobs Dama de Compañia 11" quality="100" />
							</a>
						</div>
						<div className="mx-auto my-xs-3 col-9 col-md-4">
							<a href="https://www.cardiffdesires.co.uk/" target="_blank" loading="eager" rel="noreferrer">
								<img width={480} height={60} src="/images/jobs/bottom/98.png" placeholder="DOMINANT_COLOR" loading="lazy" alt="banners jobs Dama de Compañia 12" quality="100" />
							</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://escortlandeurope.com/" rel="noopener noreferrer">
								<img border="0" src="https://escortlandeurope.com/wp-content/uploads/2023/05/EL1.png" alt="EscortLand Europe" width="720" height="90" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://escortlanddenmark.com/" rel="noopener noreferrer">
								<img border="0" src="https://escortlanddenmark.com/wp-content/uploads/2023/05/1.png" alt="EscortLand Denmark" width="720" height="90" />
							</a>
						</div>
					</div>
					<div className="row justify-content-between my-3">	
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">					
							<a href="https://www.amsterdamhotcallgirls.com/" target="_blank" rel="noopener noreferrer">
								<img class="wp-image-2058 size-full" title="Amsterdam Escort" src="http://www.amsterdamhotescort.com/wp-content/uploads/2019/07/Banner-01-728-X-90.jpg" alt="Amsterdam Escort" width="728" height="90" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a href="https://www.amsterdamhotescort.com/" target="_blank" rel="noopener noreferrer">
								<img class="alignnone wp-image-2060 size-full" title="Amsterdam Escort" src="https://www.amsterdamhotescort.com/wp-content/uploads/2019/07/Banner-01-728-X-90-1.jpg" alt="Amsterdam Escort" width="728" height="90" />
							</a>
						</div>	
					</div>
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://escortlandnorway.com/" rel="noopener noreferrer">
								<img border="0" src="https://escortlandnorway.com/wp-content/uploads/2023/06/ELno-1.jpg" alt="EscortLand Norge" width="720" height="90" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://escortlandportugal.com/" rel="noopener noreferrer">
								<img border="0" src="https://escortlandportugal.com/wp-content/uploads/2023/06/ELpt-1.jpg" alt="EscortLand Portugal Site de Acompanhantes" width="720" height="90" />
							</a>
						</div>
					</div>
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://escortlandsweden.com/" rel="noopener noreferrer">
								<img border="0" src="https://escortlandsweden.com/wp-content/uploads/2023/06/ELse-1.jpg" alt="EscortLand Sverige" width="720" height="90" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://hornydex.com/" rel="noopener noreferrer">
								<img border="0" src="https://hornydex.com/wp-content/uploads/2023/06/HD-1.gif" alt="HornyDex Escort Directory" width="720" height="90" />
							</a>
						</div>
					</div>
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a href="https://erotik-escort.com/" target="_blank" title="erotik-escort.com" rel="noopener noreferrer">
								<img src="https://erotik-escort.com/wp-content/uploads/2023/06/EE1.gif" alt="erotik-escort.com" title="erotik-escort.com" />
							</a>							
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a href="https://sex-oglasnik.com" target="_blank" title="sex-oglasnik.com" rel="noopener noreferrer">
								<img src="https://sex-oglasnik.com/wp-content/uploads/2023/06/SO-1.gif" alt="sex-oglasnik.com" title="sex-oglasnik.com" />
							</a>
						</div>
					</div>
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://escortfete.com" rel="noopener noreferrer">
								<img border="0" src="http://escortfete.com/wp-content/uploads/2023/06/EF-1.jpg" alt="EscortFete" width="720" height="90" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://ragazzeescort.com/" rel="noopener noreferrer">
								<img border="0" src="https://ragazzeescort.com/wp-content/uploads/2023/06/RE1.jpg" alt="Ragazze Escort" width="720" height="90" />
							</a>
						</div>
					</div>
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="http://escortslovakia.com/" rel="noopener noreferrer">
								<img border="0" src="https://escortslovakia.com/wp-content/uploads/2023/06/ES1.jpg" alt="Escort Slovakia" width="720" height="90" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://escortadsau.com/" rel="noopener noreferrer">
								<img border="0" src="https://escortadsau.com/wp-content/uploads/2023/06/EAA1.jpg" alt="EscortAds Australia" width="720" height="90" />
							</a>							
						</div>
					</div>
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://israeliescort.com/" rel="noopener noreferrer">
								<img border="0" src="https://israeliescort.com/wp-content/uploads/2023/06/IE1.jpg" alt="Israeli Escort" width="720" height="90" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://escortadslatvia.com" rel="noopener noreferrer">
								<img border="0" src="https://escortadslatvia.com/wp-content/uploads/2023/06/EAL1.jpg" alt="EscortAds Latvia" width="720" height="90" />
							</a>						
						</div>
					</div>
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://escortlithuania.com" rel="noopener noreferrer">
								<img border="0" src="https://escortlithuania.com/wp-content/uploads/2023/06/EL1.jpg" alt="Escort Lithuania" width="720" height="90" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://huescort.com/" rel="noopener noreferrer">
								<img border="0" src="https://huescort.com/wp-content/uploads/2023/03/Magyar-1.jpg" alt="Magyar lányok" width="720" height="90" />
							</a>
						</div>
					</div>
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://estoniaescort.com/" rel="noopener noreferrer">
								<img border="0" src="https://estoniaescort.com/wp-content/uploads/2023/03/Estoniab1.jpg" alt="Estonia Escort" width="720" height="90" />
							</a>							
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://escortuae.com/" rel="noopener noreferrer">
								<img border="0" src="https://escortuae.com/wp-content/uploads/2023/05/UAE1.png" alt="Escort Girls and Erotic Ads from United Arab Emirates" width="720" height="90" />
							</a>
						</div>
					</div>
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://chicasespaña.com" rel="noopener noreferrer">
								<img border="0" src="https://xn--chicasespaa-beb.com/wp-content/uploads/2023/06/CE1.jpg" alt="Chicas España" width="720" height="90" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-6">
							<a target="_blank" href="https://erokinks.com/" rel="noopener noreferrer">
								<img border="0" src="https://erokinks.com/wp-content/uploads/2023/06/EK1.gif" alt="EroKinks" width="720" height="90" />
							</a>
						</div>
					</div>
	
					<div className="row justify-content-between my-3">						
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://totalescort.dk" target="_blank" title="totalescort.dk" rel="noopener noreferrer">
								<img width={480} height={60} alt="totalescort.dk" src="https://totalescort.dk/wp-content/uploads/2023/01/Banner.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.escortily.at" target="_blank" title="www.escortily.at" rel="noopener noreferrer">
								<img width={480} height={60} alt="www.escortily.at" src="https://www.escortily.at/wp-content/uploads/2023/03/Banner.png"/>
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://eskort-sverige.se" target="_blank" title="eskort-sverige.se" rel="noopener noreferrer">
								<img width={480} height={60} alt="eskort-sverige.se" src="https://eskort-sverige.se/wp-content/uploads/2023/01/Banner.png"/>
							</a>
						</div>
					</div>		
					
					<div className="row justify-content-between my-3">						
						
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.sexyholky.net" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} alt="www.sexyholky.net" src="https://www.sexyholky.net/wp-content/uploads/2022/08/banner.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://fick-markt.net" target="_blank" title="fick-markt.net" rel="noopener noreferrer">
								<img width={480} height={60} alt="fick-markt.net" src="https://fick-markt.net/wp-content/uploads/2023/03/Banner-1.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://escort-hellas.com" target="_blank" title="escort-hellas.com" rel="noopener noreferrer">
								<img width={480} height={60} alt="escort-hellas.com" src="https://escort-hellas.com/wp-content/uploads/2023/01/Ban.png" />
							</a>
						</div>
					</div>	

					<div className="row justify-content-between my-3">						
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://escortprivedames.be" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} alt="escortprivedames.be" src="https://escortprivedames.be/wp-content/uploads/2022/08/banner.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://ladies69.ch" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} alt="ladies69.ch" src="https://ladies69.ch/wp-content/uploads/2022/08/banner.png"/>
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.sexsludinajumi.net" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} alt="www.sexsludinajumi.net" src="https://www.sexyholky.net/wp-content/uploads/2022/08/banner.png" />
							</a>
						</div>
					</div>	
					
					<div className="row justify-content-between my-3">						
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://vladimirescorts.com" target="_blank" title="Vladimir Escort Directory" rel="noopener noreferrer"> 
								<img width={480} height={60} src="https://vladimirescorts.com/wp-content/uploads/2023/12/banner.jpg" alt="VladimirEscorts" /> 
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://escort-lust.com" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} alt="escort-lust.com" src="https://escort-lust.com/wp-content/uploads/2023/02/banner.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://bahamasescorts.com" target="_blank" title="Bahamas EscortDirectory" rel="noopener noreferrer"> 
								<img width={480} height={60} src="https://bahamasescorts.com/wp-content/uploads/2023/12/banner.jpg" alt="BahamasEscorts"/>
							</a>
						</div>
					</div>	

					<div className="row justify-content-between my-3">						
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://escort809.com/" target="_BLANK" rel="noopener noreferrer">
								<img width={480} height={60} src="https://escort809.com/wp-content/uploads/Banner-Link-468x60-1.gif" alt="Escort809"/>
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://discoveryescort.com" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://discoveryescort.com/wp-content/uploads/2024/02/Discovery-Banner-468x60-1.gif" alt="DiscoveryEscort.com" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://latinasinbahamas.com" target="_blank" title="Latinas EscortDirectory" rel="noopener noreferrer"> 
								<img width={480} height={60} src="https://latinasinbahamas.com/wp-content/uploads/2023/12/banner.jpg" alt="LatinaEscorts" /> 
							</a>
						</div>					
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://webbunnys.com/" target="_blank" title="Webbunnys Sexkontakte" rel="noopener noreferrer">
								<img width={480} height={60} src="https://webbunnys.com/banner/banner.gif" alt="Webbunnys Sexkontakte" border="0"  />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.gpvicio.com.br" title="Acompanhantes e Garotas de Programa GPVicio" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://www.gpvicio.com.br/parceiros/gpvicio_banner_468x60.gif" alt="Acompanhantes e Garotas de Programa GPVicio" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://escortsmexico.net" title="ESCORTS MEXICO" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://escortsmexico.net/banners/BN468x60.png" alt="ESCORTS MEXICO" />
							</a>
						</div>				
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.slixa.com/" target="_blank" title="Slixa" rel="noopener noreferrer">
								<img width={480} height={60} src="https://affiliatebanners.slixa.com/62f77c380347a.gif" title="Slixa" alt="Slixa" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://luxure.cl/" target="_blank" title="Escorts Luxure.cl" rel="noopener noreferrer">
								<img width={480} height={60} class="lazyload" src="https://luxure.cl/assets/frontend/img/banners/banner-luxure-468x60.png" alt="Escorts Luxure.cl" /> 
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://australianescorts.au" title="Escort Girls" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60}  src="https://australianescorts.au/img/banner.png" alt="Escort Girls" />
							</a>
						</div>
					</div>
					
					<div className="row justify-content-between my-3">	
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.pattayaescortgirls.com/" title="pattayaescortgirls.com" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://images.squarespace-cdn.com/content/v1/61d80c3098b6370be072dcf6/d9f06dd5-a1a8-4777-8964-b55f98318ec8/468.png?format=500w" alt="Pattaya Escorts" />
							</a>
						</div>		
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.escortsnearby.com.au" title="Largest directory of Private and Independent Escorts Girls & Babes for BDSM Adult Service in Australia" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://www.escortsnearby.com.au/images/ads-links/ads-468x60.gif" alt="Find Largest directory of Private and Independent Escorts Girls & Babes for BDSM Adult Service in Australia" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<p>
								<a href="https://www.escortpromo.co.uk/" title="London Escort Promo Directory" target="_blank" rel="noopener noreferrer">
									<img width={480} height={60} src="https://www.escortpromo.co.uk/wp-content/uploads/2018/01/escort-promo-directory-2-468.jpg" alt="Escort Promo Directory"/>
								</a>
							</p>
						</div>
											
					</div>

					<div className="row justify-content-between my-3">			
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://escortpiubella.com" title="Escortpiubella.com" target="_blank" rel="noopener noreferrer">
								<img src="https://escortpiubella.com/wp-content/themes/escortPiuBella/images/468x60-banner-escortpiubella.gif" alt="Escort Più Bella" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<p>
								<a href="https://geneva.flymetoyou.ch" title="Geneva Escorts " target="_blank" rel="noopener noreferrer">
									<img width={480} height={60} src="http://geneva.flymetoyou.ch/wp-content/uploads/2024/05/Geneva-Escorts.png" alt="Geneva Escorts " class="wp-image-2067 aligncenter size-full" />
								</a>
							</p>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="http://city-love-companions.com/escorts/colombia/" target="_blank" rel="noopener noreferrer">
								<img src="http://city-love-companions.com/wp-content/uploads/2019/11/Colombia-escorts.jpg" alt="Colombia escorts and erotic city guides"  />
							</a>
						</div>						
					</div>
										
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.slixa.com/" target="_blank" title="Slixa" rel="noopener noreferrer">
								<img width={468} height={60} src="https://affiliatebanners.slixa.com/62f77c380347a.gif" title="Slixa" alt="Slixa" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://epicure.club" title="EPICURE CLUB" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://epicure.club/sites/default/files/banners/epicure-club.gif" alt="epicure.club" />
							</a>
						</div>						
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.londongirlmassage.co.uk/" title="High class escort in London" target="_blank" rel="noopener noreferrer"> 
								<img width={480} height={60} src="https://www.londongirlmassage.co.uk/images/banner.jpg" alt="High class escort in London" />
							</a>
						</div>					
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.selectanescort.com/escorts-all/london-escorts.html" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://www.easymail4all.co.uk/banner/selectanescort468x60.gif" alt="SelectAnEscort London Escorts" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.divine-girls.com" title="Divine Girls Worldwide Escorts Directory" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://www.divine-girls.com/front-end/assets/images/banners/divine-girls_468x60.gif" alt="Divine Girls Worldwide Escorts Directory"/>
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a title="Escort Finder UK - UK Escort Directory" href="https://www.escortfinderuk.co.uk/" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} alt="Escort Finder UK - UK Escort Directory" src="https://www.escortfinderuk.co.uk/images/escort-finder-banner.jpg" />
							</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a title="Fire Escorts - UK Escort Directory" href="https://www.fireescorts.co.uk" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} alt="Fire Escorts - UK Escort Directory" src="https://www.fireescorts.co.uk/images/fire-escorts-banner.jpg" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.londonasianescortgirl.co.uk" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://www.londonasianescortgirl.co.uk/images/banner_468_60.jpg" alt="Elite London Asian Escort"/>
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a title="Find Escorts | Escort Directory UK" href="https://www.find-escorts.co.uk/" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} alt="Find Escorts | Escort Directory UK" src="https://www.find-escorts.co.uk/images/find-escorts-link.jpg" />
							</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.escortgirls.guru/" target="_blank"  rel="noopener noreferrer">
								<img src="https://escortgirls.guru/img/v2/sticks/stick-13.jpg" alt="escortgirls guru" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
						<a href="https://www.poutanes24.com" target="_blank" title="poutanes24" rel="noopener noreferrer">
							<img width={468} height={60} decoding="async" src="https://www.poutanes24.com/wp-content/uploads/2024/03/banner.png" />
						</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://totalescort.dk/" target="_blank" title="totalescort.dk" rel="noopener noreferrer">
								<img width={468} height={60} src="https://totalescort.dk/wp-content/uploads/2023/01/Banner.png" />
							</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.ireland-girls.com" target="_blank" title="Ireland Girls" rel="noopener noreferrer">
								<img width={468} height={60} src="https://www.ireland-girls.com/wp-content/uploads/2024/04/banner.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://novellitxxx.com" title="novanoveller" target="_blank" rel="noopener noreferrer">
								<img width={468} height={60} alt="novanoveller" src="https://novellitxxx.com/wp-content/uploads/2023/07/ban.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
						<a href="https://istoriestouerwta.com" title="novanoveller" target="_blank" rel="noopener noreferrer">
							<img width={468} height={60} alt="novanoveller" src="https://istoriestouerwta.com/wp-content/uploads/2023/07/ban.png" />
							</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.fick-treff.eu" target="_blank" rel="noopener noreferrer">
								<img width={468} height={60} alt="www.fick-treff.eu"  src="https://www.fick-treff.eu/wp-content/uploads/2022/08/banner1.png"/>
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.ludere24.com" target="_blank" rel="noopener noreferrer">
								<img width={468} height={60} alt="www.ludere24.com" src="https://www.ludere24.com/wp-content/uploads/2024/03/banner.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.pillu24.com" target="_blank" rel="noopener noreferrer">
								<img width={468} height={60} alt="www.pillu24.com" src="https://www.pillu24.com/wp-content/uploads/2023/01/banner.png"/>
							</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.eskorter69.com/" target="_blank" rel="noopener noreferrer">
								<img width={468} height={60} alt="www.eskorter69.com" src="https://www.eskorter69.com/wp-content/uploads/2024/03/banner.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.adultwork.se/" target="_blank" rel="noopener noreferrer">
								<img width={468} height={60} alt="adultwork.se" src="https://www.adultwork.se/wp-content/uploads/2024/06/banner.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://eskort-sverige.se/" target="_blank" title="eskort-sverige.se" rel="noopener noreferrer">
								<img width={468} height={60} src="https://eskort-sverige.se/wp-content/uploads/2023/01/Banner.png" />
							</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.finescort.eu" target="_blank" rel="noopener noreferrer">
								<img width={468} height={60} decoding="async" alt="finescort" src="https://www.finescort.eu/wp-content/uploads/2024/06/banner.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.escortfin.com" target="_blank" rel="noopener noreferrer">
								<img width={468} height={60} alt="EscortFin" src="https://www.escortfin.com/wp-content/uploads/2024/03/banner-1.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.escortily.ch" target="_blank" rel="noopener noreferrer">
								<img width={468} height={60} alt="escortily.ch" src="https://www.escortily.ch/wp-content/uploads/2024/06/banner.jpg" />
							</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-3">
							<a href="https://www.selectanescort.com/escorts-all/london-escorts.html" target="_blank" rel="noopener noreferrer">
								<img src="https://www.easymail4all.co.uk/banner/selectanescort150x60.gif" alt="SelectAnEscort London Escorts"  />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-3">
							<a href="https://www.escortmod.com/" title="Escortmod.com" target="_blank" rel="noopener noreferrer">
								<img alt="Escortmod.com" src="https://www.escortmod.com/images/banner/234x60.gif" title="Escortmod.com" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-3">
							<a href="https://secretbunnies.com/all-escorts/" target="_blank" rel="noopener noreferrer">
								<img src="https://i.ibb.co/Wvhx0TL/secretbunnies.jpg" alt="Secret Bunnies" border="0" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-3">
							<a href="https://lostdubai.com/all-call-girls/" target="_blank" rel="noopener noreferrer">
								<img src="https://i.ibb.co/jw8Y046/lostdubai.jpg" alt="LostDubai.com" border="0" />
							</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://escortmodels.org/" target="_blank" rel="noreferrer noopener" title="Worldwide Escort Directory" >
								<img width={480} height={60} loading="lazy" src="https://i.ibb.co/n6s3HR4/Escortmodels-Banner-01.jpg" alt="escortmodels.org/" title="Escortmodels.org" />
							</a>
						</div>	
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://city-love-companions.com/independent-escorts/" target="_blank" rel="noopener noreferrer">
								<img width={480} height={60} src="https://city-love-companions.com/wp-content/uploads/2019/06/Independent-escorts-1.jpg" loading="lazy" alt="City Love Companions" border="0" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<div align="center">
								<a href="https://www.punterlink.co.uk/main/bogota-escorts" target="_blank" rel="noopener noreferrer">
									<img width={480} height={60} src="https://www-punterlink-co-uk.dualstackcdn.com/resources/graphics/rlinks/1st3.gif" alt="Punterlink Bogota Escorts Escorts" loading="lazy" title="Punterlink Bogota Escorts Escorts" border="0" />
								</a>
							</div>
						</div>					
					</div>

					<div className="row justify-content-between my-3">						
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://1escorts.net/" title="1escorts.net" target="_blank" rel="noopener noreferrer">
								<img alt="1escorts.net" title="1escorts.net" src="https://1escorts.net/images/banner120-240.png" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.escortspots.com" title="Escortspots.com" target="_blank" rel="noopener noreferrer">
								<img width={250} height={200} src="https://cdn.escortspots.com/static/bn/1iom0odgr4.gif" alt="La Celestina" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.redlightdistrict.nl" title="Red Light District" target="_blank" rel="noopener noreferrer">
								<img width={200} height={200}  src="https://cdn.redlightdistrict.nl/static/bn/51esnudkdv.gif" alt="Red Light District" />
							</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-3">
							<a href="https://escortnews.eu" title="Escort Girls" target="_blank" rel="noreferrer">
								<img loading="lazy" src="https://static.escortnews.eu/125x60.gif" alt="Escort Girls" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-3">
							<a href="https://www.escortnews.com" title="Escort Girls" target="_blank" rel="noreferrer">
								<img loading="lazy" src="https://static.escortnews.com/new/banners-2021/88x32.gif" alt="Escort Girls" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-3">
							<a href="https://www.escortnews.com" title="Escort Girls" target="_blank" rel="noreferrer noopener">
								<img src="https://static.escortnews.com/new/banners-2021/88x32.gif" alt="Escort Girls" />
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-3">
							<a href="https://topflightescorts.com" target="_blank" rel="noopener noreferrer">
								<img src="https://topflightescorts.com/our-banners/tfe-button-blue.gif" />
							</a>
						</div>
					</div>
					
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://nympho.fi" target="_blank" title="Nympho.fi - Official escort & massage service" rel="noopener noreferrer">nympho.fi
							</a>
						</div>					
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://weescorts.com" target="_blank" rel="noopener noreferrer">weescorts.com
							</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://hotslips.de/getragene-unterwaesche-verkaufen/" target="_blank" rel="noreferrer noopener">Getragene unterwäsche verkaufen</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">						
					<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://brugtetrusser.dk/" target="_blank" rel="noreferrer noopener">Brugtetrusser</a>
						</div>						
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://www.forthemforus.com/" target="_blank" title="forthemforus.com" rel="noopener noreferrer">forthemforus.com</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://www.cityoflove.com" target="_blank" rel="noreferrer">www.cityoflove.com</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://en.devozki.com/" target="_blank" rel="noreferrer">en.devozki.com</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.seksosusitikimai.net" target="_blank" rel="noopener noreferrer">seksosusitikimai.net</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://direct-seks.nl" target="_blank" rel="noopener noreferrer">direct-seks.nl</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">						
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.eskortejenter69.com" target="_blank" title="www.eskortejenter69.com" rel="noopener noreferrer">www.eskortejenter69.com</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://escortlounge.org/" target="_blank" rel="noopener noreferrer"  title="Worldwide Escort Directory">escortlounge.org</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://brugtetrusser.dk/" target="_blank" rel="noopener noreferrer">Brugte trusser</a>
						</div>
					</div>	

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://hotslips.de/" target="_blank" rel="noopener noreferrer">Getragene unterwäsche</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://brugtetrusser.dk/" target="_blank" rel="noreferrer noopener">Brugtetrusser</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://anvanda-trosor.se/produktkategori/anvanda-trosor/" target="_blank" rel="noreferrer noopener">Köpa använda trosor</a>
						</div>	
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<strong>
								<a target="_blank" title="Exquisite European Companions!" href="http://www.seductiveagency.com"  rel="noreferrer noopener">European Girls</a>
							</strong>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a target="_blank" title="Exquisite European Girls" href="http://www.seductiveagency.com" rel="noreferrer noopener">Seductive Agency</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-5 col-md-4">
							<a href="https://www.fgirl.ch/" target="_blank" rel="follow" title="Fgirl - Annuaire N°1 pour escorts et masseuses en Suisse">Annuaire N°1 pour escorts et masseuses en Suisse</a>
						</div>	
					</div>

					<div className="row justify-content-between my-3">												
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://australianescorts.au/" title="australianescorts.au" target="_blank" rel="noopener noreferrer">australianescorts.au</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.fick-markt.net/" target="_blank" title="www.fick-markt.net" rel="noopener noreferrer">.fick-markt.net</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.myverifiedescorts.com/" title="myverifiedescorts.com" target="_blank" rel="noopener noreferrer">myverifiedescorts</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.mccoysguide.com" title="mccoysguide.com" target="_blank" rel="noopener noreferrer">mccoysguide</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://deutschlandescorts.de/" title="deutschlandescorts.de" target="_blank" rel="noopener noreferrer">escort directory Germany</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.happyescorts.com/" title="HappyEscorts.com" target="_blank" rel="noopener noreferrer">HappyEscorts.com - Biggest Escorts Search Engine</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a title="newwarsawescort" href="https://newwarsawescort.escortbook.com/#bd8994" target="_blank" rel="noopener noreferrer">newwarsawescort</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://warsawescortoutcall.escortbook.com/#bd8996" target="_blank" rel="noopener noreferrer">warsawescortoutcall</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://warsawescortpoland.escortbook.com/#bd8995" target="_blank" rel="noopener noreferrer">warsawescortpoland</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.sexkuulutused.com" target="_blank" rel="noopener noreferrer">www.sexkuulutused.com</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://topwarsawescort.escortbook.com/#bd8997" target="_blank" rel="noopener noreferrer">topwarsawescort</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://warsawescortagencypoland.escortbook.com/#bd8998" target="_blank" rel="noopener noreferrer">warsawescortagencypoland</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">						
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://erotik69.ch" target="_blank" rel="noopener noreferrer">erotik69.ch</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://nympho.eu/assets/images/banners/nympho_no_banner.png" target="_blank" rel="noopener noreferrer">nympho.eu</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.sekszpaniami.pl" target="_blank" rel="noopener noreferrer">www.sekszpaniami.pl</a>
						</div>
					</div>
					
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://retiredseo.cc/" target="_blank" rel="noopener noreferrer">retiredseo</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.hxdoll.com/" target="_blank" rel="noopener noreferrer">Hxdoll</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.thepornblender.com/" target="_blank" rel="noopener noreferrer">ThePornBlender</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.mehakiyer.com/" title="mehakiyer.com" alt="Chennai escorts" target="_blank" rel="noopener noreferrer">mehakiyer.com</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.mehakiyer.com/" title="mehakiyer.com" alt="Chennai escorts" target="_blank" rel="noopener noreferrer">mehakiyer.com</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://onaiscenjega.com" target="_blank" rel="noopener noreferrer">onaiscenjega.com</a>
						</div>
					</div>
					
					<div className="row justify-content-between my-3">
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://escortify.dk/" target="_blank" rel="noopener noreferrer">Escortify.dk</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://www.sinlips.com/escorts/ethnicity/latina/" title="SinLips London escort agency" target="_blank" rel="noopener noreferrer">SinLips London escort agency</a>
						</div>
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<a href="https://warsawescortagency.escortbook.com/#bd8999">warsawescortagency</a>
						</div>
					</div>

					<div className="row justify-content-between my-3">
						
						<div className="d-none d-md-block mx-auto my-xs-3 col-12 col-md-4">
							<p>
								<a href="https://eroescort.pl" title="ogłoszenia towarzyskie" target="_blank" rel="noopener noreferrer">
									<strong>eroEscort.pl</strong> - Największa wyszukiwarka ogłoszeń towarzyskich
								</a>
							</p>
						</div>
					</div>
			
				</div>
			</div>
    	</div>
	)
}
